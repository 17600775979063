import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { Tab, VisualObject, Conf } from "../../shared/models";
import { VisualObjectGroup } from "./visualObjectGroup";
import { VisualObjectGroupWrapper } from "./visualObjectGroupWrapper";
import { ProductDataStore } from "../../shared/providers/productData";
import { TabDisplayStyle } from "../../shared/providers";
import { VisualObjectVisibilityService } from "./visualObjectVisibilityService";
import { VisualObjectHelper } from "../parameters/shared/visualObjectHelper";
import { VisualObjectUIDataService } from "./visualObjectUIDataService";

@Injectable()
export class VisualObjectContainerService {
  
  constructor(
    @Inject(VisualObjectVisibilityService) public visualObjectVisibilityService: VisualObjectVisibilityService,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    @Inject(VisualObjectUIDataService) public visualObjectUIDataService: VisualObjectUIDataService
  ) {    
  }

  /**
   * Creates the <VisualObjectGroupWrapper>.
   * @param visualObjectId
   */
  create(visualObjectId: number, tab: Tab, conf: Conf): void {

    // Retreive visual object.
    let entity: VisualObject = this.productStore.getEntity(visualObjectId) as VisualObject;

    // Get visibility
    let isVisible: boolean = this.visualObjectVisibilityService.isVisualObjectVisible(conf, entity);

    // Deal with cached data If cache alraedy have it.
    if (this.visualObjectUIDataService.has(+visualObjectId)) {
      let cachedWrapper = this.visualObjectUIDataService.get(+visualObjectId);

      // Only update the object if visibility changed.
      if (cachedWrapper.visible != isVisible) {
        cachedWrapper = cachedWrapper.setVisible(isVisible);
        this.visualObjectUIDataService.addOrUpdate(cachedWrapper);
      }
      return;
    }

    // Deal with new models.
    let group = new VisualObjectGroup();
    group = group.setId(visualObjectId);
    group = group.setName(entity.title);
    group = group.setCssClass(this.getVisualObjectCssClass(entity, tab));
    group = group.setGrowVisualObject(this.visualObjectHelper.growVisualObject(entity));
    group = group.setHangings(Immutable.List<number>());
    group = group.setVisualObject(entity);

    let wrapper: VisualObjectGroupWrapper = new VisualObjectGroupWrapper();
    wrapper = wrapper.setHanging(entity.hanging);
    wrapper = wrapper.setVisible(isVisible);
    wrapper = wrapper.setVisualObjectGroup(group);
    wrapper = wrapper.setLineBreak(true);
    wrapper = wrapper.setNewHangings(Immutable.List<number>()); // Empty list

    this.visualObjectUIDataService.addOrUpdate(wrapper);
  }

  protected getVisualObjectCssClass(visualObject: VisualObject, tab: Tab): string {

    let styles: string = '';
    if (this.visualObjectHelper.growVisualObject(visualObject)) {
      styles = 'grow-visual-object w-100'
    }

    if (this.visualObjectHelper.visualObjectWidth(visualObject) == "100%") {
      styles += ' w-100 ';
    }

    // Distribute the room equally among all floated parameters if they don't have width.
    // 'col' distributes the room equally. In contrast 'col-auto' makes the width same as parameter's width.
    if (tab.displayStyle == TabDisplayStyle.Accordion || tab.displayStyle == TabDisplayStyle.AccordionHeaderTab) {

      styles += visualObject.width && visualObject.width > 0 ? ' col-auto' : ' col';

    } else styles += ' col-auto';
    
    return `visual-object ${visualObject.hanging ? 'hanging' : ''} ${styles} ${visualObject.className}`;
  }

  get(visualObjectId: number): VisualObjectGroupWrapper {
    return this.visualObjectUIDataService.get(+visualObjectId);
  }

  /**
   * Setup the visual object models.
   * @param tab
   */
  setup(tab: Tab, conf: Conf): void {
    // Visual objects are setup in three steps.
    // 1- Create the visual object models and put them in cache.
    // 2- Set the hangings.
    // 3- set the line break.

    // Create visual objects.
    tab.visualObjects.forEach(id => this.create(id, tab, conf));

    // Set the hangings.
    this.makeHangings(tab);

    // Set the line break.        
    this.applyLineBreak(tab.visualObjects, true);
  }

  /**
   * Makes the hanging groups.
   * @param tab
   */
  public makeHangings(tab: Tab): void {    
    // Make empty all hangings
    tab.visualObjects.forEach(vId => {
      let wrapper = this.visualObjectUIDataService.get(+vId);
      if (wrapper.newHangings.size > 0) {
        wrapper = wrapper.setNewHangings(Immutable.List<number>());
        this.visualObjectUIDataService.addOrUpdate(wrapper);
      }
    });

    // Set the hangings.
    tab.visualObjects.forEach((vId, index) => {
      let wrapper = this.visualObjectUIDataService.get(+vId);
      if (!wrapper.visible)
        return;

      let removeHanging: boolean = false;

      // Group the hangings.
      if (index > 0 && wrapper.hanging) {
        let prevVisibleWrapper: VisualObjectGroupWrapper = this.getPreviousVisibleModel(tab.visualObjects, index, true);

        if (prevVisibleWrapper) {
          // Update the hanging list of previous visual object model.
          let hangings: Immutable.List<number> = prevVisibleWrapper.newHangings.push(wrapper.visualObjectGroup.id);
          prevVisibleWrapper = prevVisibleWrapper.setNewHangings(hangings);

          // Update the cache.
          this.visualObjectUIDataService.addOrUpdate(prevVisibleWrapper);
        }

        // If all previous visual objects are invisible then move this object to root level by changing its hanging status.
        else removeHanging = true;
      } else removeHanging = true;

      if (removeHanging && wrapper.hanging) {
        wrapper = wrapper.setHanging(false);
        this.visualObjectUIDataService.addOrUpdate(wrapper);
      }
    });

    // Compare the old and new hangings If they are changed then update the group.
    tab.visualObjects.forEach((vId, index) => {
      let wrapper = this.visualObjectUIDataService.get(+vId);

      // Comparison
      if (JSON.stringify(wrapper.newHangings) != JSON.stringify(wrapper.visualObjectGroup.hangings)) {

        // Set the new hangings.
        let group: VisualObjectGroup = wrapper.visualObjectGroup.setHangings(wrapper.newHangings);
        wrapper = wrapper.setVisualObjectGroup(group);

        // Update cache.
        this.visualObjectUIDataService.addOrUpdate(wrapper);
      }
    });
  }

  /**
   * Sets the line break for visual objects.
   * By default all visual objects are floated. In this method we read the floating position for each visual object and update the previous visual object line-break.
   * 
   * Example: Input data -> V1(floating: false, line-break: true), V2(floating: true, line-break: true), V3(floating: false, line-break: true)
   *     Expected output -> V1(floating: false, line-break: false), V2(floating: true, line-break: true), V3(floating: false, line-break: true)
   *
   * In above example, you can see that the line-break is only updated for previous element(V1) to accomodate V3 in the same row.
   *
   * @param visualObjectIds
   * @param isRootVisualObject
   */
  public applyLineBreak(visualObjectIds: Immutable.List<number>, isRootVisualObject: boolean): void {

    // Reset line break before applying.
    visualObjectIds.forEach(vId => {

      let currentWrapper: VisualObjectGroupWrapper = this.visualObjectUIDataService.get(+vId);      
      currentWrapper = currentWrapper.setLineBreak(true);
      this.visualObjectUIDataService.addOrUpdate(currentWrapper);

    });

    // Update the line-break.
    visualObjectIds.forEach((vId, index) => {

      let currentWrapper: VisualObjectGroupWrapper = this.visualObjectUIDataService.get(+vId);
      let entity: VisualObject = this.productStore.getEntity(+vId);

      // Don't continue If it is hanging or hidden or index is zero.
      if (index == 0 || (isRootVisualObject && currentWrapper.hanging) || !currentWrapper.visible)
        return;

      if (entity.floatingPosition) {

        // As floating is set by default to all elements, just remove line break from previous element to make it functional.
        let prevWrapper: VisualObjectGroupWrapper = this.getPreviousVisibleModel(visualObjectIds, index, isRootVisualObject);

        if (prevWrapper) {

          // Remove the line break from previous element so that current one could appear in same row.
          if (prevWrapper.lineBreak) {

            // Update value and cache.
            prevWrapper = prevWrapper.setLineBreak(false);
            this.visualObjectUIDataService.addOrUpdate(prevWrapper);
          }

        }

        else {
          // if the first <VisualObject> is set 'floating' then <prevModel> would be null.
        }
      }

      // Apply floating within hanging list.
      if (currentWrapper.visualObjectGroup.hangings.size > 0)
        this.applyLineBreak(currentWrapper.visualObjectGroup.hangings, false);
    });
  }

  /**
   * Clears the cached objects. It forces all visual objects to be rerendered.
   */
  public clear(): void {
    this.visualObjectUIDataService.clear();
  }

  /**
   * Returns the previous visible visualObject model.
   * @param list
   * @param indexLimit
   * @param isRootVisualObject
   */
  public getPreviousVisibleModel(list: Immutable.List<number>, indexLimit: number, isRootVisualObject: boolean): VisualObjectGroupWrapper {

    for (let index = indexLimit - 1; index >= 0; index--) {
      let visualObjectId: number = list.get(+index);
      let model: VisualObjectGroupWrapper = this.visualObjectUIDataService.get(+visualObjectId);

      if (isRootVisualObject && model.hanging)
        continue;

      if (model.visible)
        return model;
    }

    return null;
  }

  /**
   * Returns the root visual object groups.
   * @param tab
   */
  getRootVisualObjectGroups(tab: Tab): VisualObjectGroup[] {

    let groups: VisualObjectGroup[] = [];
    tab.visualObjects.forEach(vId => {

      let wrapper: VisualObjectGroupWrapper = this.visualObjectUIDataService.get(vId);
      if (!wrapper.hanging && wrapper.visible)
        groups.push(wrapper.visualObjectGroup);
    });

    return groups;
  }

  /**
   * Returns the hanging VisualObjectGroup.
   * @param vId
   */
  hangings(vId: number): VisualObjectGroup[] {
    
    let hangingList: VisualObjectGroup[] = [];
    let wrapper: VisualObjectGroupWrapper = this.visualObjectUIDataService.get(vId);
    wrapper.visualObjectGroup.hangings.forEach(vId => {

      let wrapper: VisualObjectGroupWrapper = this.visualObjectUIDataService.get(vId);
      if (wrapper.visible)
        hangingList.push(wrapper.visualObjectGroup);
    });

    return hangingList;
  }

  // Note! This is a temporary method introduced to control the flex layout at root level.
  hasFlexContainer(tab: Tab): boolean {
    
    let isFlexObject: boolean = false;
    this.visualObjectUIDataService.getAll().forEach(x => {

      const group: VisualObjectGroup = x.visualObjectGroup;
      if (group) {

        let entity: VisualObject = this.productStore.getEntity(group.id) as VisualObject;
        if (tab.longId == entity.tabId && x.visualObjectGroup.growVisualObject)
          isFlexObject = true;

      }
    });

    return isFlexObject;
  }

}