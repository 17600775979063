import { ChangeDetectorRef, Component, ViewChildren, QueryList } from "@angular/core";
import * as Immutable from "immutable";
import { ListItemModel, ListModel, ListComponent } from "../../../shared/components/list";
import { AttributeDecoration, ConfAttributeValue, AttributeDecorationItem, GroupConfUIItem, StoredAttributeValue } from "../../../shared/models";
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { ListModelCacheService } from "../providers/listModelCacheService";
import { BaseDetailItemComponent } from "../shared/baseDetailItemComponent";
import { ListRowModel } from "../shared/listRowModel";
import { PopupService, PopperConfig, PopoverService } from "../../../../shared/components";
import { GlobalDataStore } from "../../../shared/providers/globalData";

@Component({ template: '' })
export class AbstractAttributesDetailComponent extends BaseDetailItemComponent {

  public attributesValues: Array<ConfAttributeValue> = [];
  public storedAttributesValues: Array<StoredAttributeValue> = [];
  protected row = new ListRowModel();
  public detailTitle: string = this.strings.Attributes;

  @ViewChildren(ListComponent)
  public criteriaComponents: QueryList<ListComponent>;

  constructor(
    public cdr: ChangeDetectorRef,
    public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService,
    public productStore: ProductDataStore,
    public modelsService: ListModelCacheService,
    public popupService: PopupService,
    public globalDataStore: GlobalDataStore,
    public popoverService: PopoverService
  ) {
    super(confPageSessionService);
  }
  
  onDataReady(): void {
    // overriden
  }
 
  init(): void {

    let conf = this.confPageSessionService.activeConfiguration;    
    let values = conf.attributeValues;

    if (!values) {
      this.visible = false;
      return;
    }

    this.rows = this.rows.clear();
    this.row.cells = [];

    let visibleAttributeItemById: Map<number, AttributeDecorationItem> = new Map<number, AttributeDecorationItem>();

    let attributeDecorationItems = Immutable.List<AttributeDecorationItem>();

    let setDecorationItemByItem = (attrDecorationId: number) => {

      let attrDec: AttributeDecoration = this.productStore.getEntity(attrDecorationId);
      if (!attrDec)
        return;

      attributeDecorationItems = attrDec.items;

      attrDec.items.forEach(x => {

        visibleAttributeItemById.set(x.attributeId, x);

      });

    }

    if (this.confUIItem) {

      // Grouped attribute decorations.
      if (this.confUIItem instanceof GroupConfUIItem) {

        this.detailTitle = this.globalDataStore.getGlobalData().globalSettings.showCurrentOrStoredAttributes.startsWith("Current") ? this.strings.CalculatedData : this.strings.Attributes;

        // Decorations are available at items level. Loop through them and get all decoration items
        this.confUIItem.items.forEach(y => {          
            setDecorationItemByItem(y.id);
        });

      }

      // Ungrouped attribute decorations.
      else {

        this.detailTitle = this.strings.Attributes;

        setDecorationItemByItem(this.confUIItem.id);

        let attributeDecoration: AttributeDecoration = this.productStore.getEntity<AttributeDecoration>(this.confUIItem.id);
        if (attributeDecoration && attributeDecoration.title && attributeDecoration.title != "")
          this.detailTitle = attributeDecoration.title;
      }
    }

    attributeDecorationItems.forEach(decorationItem => {
      let id = decorationItem.attributeId;
      if (!values.contains(id) || !visibleAttributeItemById.has(id))
        return;

      // Add into cells.
      let listModel = this.getAttributesDetail(id, visibleAttributeItemById);

      if (listModel)
        this.row.cells.push(listModel);
      else
        this.visible = false;
    });

    // Add empty cell to complete the border
    if (this.shouldAddEmptyCell(this.row.cells.length)) {
      this.row.cells.push(new ListModel());
    }

    this.visible = this.row.cells.length > 0;
    this.rows = this.rows.push(this.row);
  }

  getAttributesDetail(id: number, decorationIdById: Map<number, AttributeDecorationItem>, isPopup = false): ListModel {

    let conf = this.confPageSessionService.activeConfiguration;
    let attrValue = this.confStore.getConfValue(conf.longId, this.confPageSessionService.confSessionId, id);

    // Make it visible If it contains any attributes.
    if (!(attrValue instanceof ConfAttributeValue)) {
      return;
    }

    // Create list model, set attributes detail
    let list: ListModel = this.modelsService.getCachedIfValueEqual(id.toString(), attrValue);
    if (list) {

      // Add into cells.
      return list;

    } else list = new ListModel();

    list = list.setTitle(attrValue.title);
    // Customization
    if (this.detailTitle != 'Shopping cart')
    // End of customization
    list = list.setIcon(`${attrValue.valueType.toLowerCase()}param`);
    list = list.setBackgroundImage("attributedecoration");
    list = list.setEmptyValueText("<No Value>"); // TODO: Localize it.
    list = list.setIsPrintView(this.isPrintView);
    list = list.setId(attrValue.longId.toString());


    // Set conf value.
    let itemModel: ListItemModel = new ListItemModel();
    let storedItemModel: ListItemModel = new ListItemModel();

    if (decorationIdById && decorationIdById.has(attrValue.longId)) {

      let decorationItem: AttributeDecorationItem = decorationIdById.get(attrValue.longId);

      // TODO: Used in modelsservice for caching???
      list = list.setTag(decorationItem);
    }

    let value: string;
    let unit: string;

    // If the stored value should be shown first, the calculated value in a popup
    let storedFirst = this.globalDataStore.getGlobalData().globalSettings.showCurrentOrStoredAttributes.startsWith("Stored");

    // Create a stored value list item
    if (storedFirst || isPopup) {
      if (conf.storedData.attributeValues) {
        let storedAttrVal = conf.storedData.attributeValues.find(x => x.longId == id);
        if (storedAttrVal) {
          if (storedAttrVal.value != null && attrValue.valueType == "Bool")
            value = storedAttrVal.value == '1' ? this.strings.Yes : this.strings.No;
          else
            value = storedAttrVal.value;

          unit = isPopup && storedAttrVal.value ? `${this.nullAsEmptyStringOrAddSpace(storedAttrVal.unit)}<span>(${this.strings.Stored})</span>` : storedAttrVal.value ? storedAttrVal.unit : "";
        }
      }
      storedItemModel = this.initItemModel(value, unit, storedItemModel, !storedFirst && isPopup);
    }

    // Create a calculated value list item
    if (!storedFirst || isPopup) {
      // Read formatted value
      if (list.tag instanceof AttributeDecorationItem && list.tag.numericFormat)
        value = attrValue.valueByNumericFormat.get(list.tag.numericFormat);
      else
        value = attrValue.value;

      unit = isPopup && attrValue.value ? `${this.nullAsEmptyStringOrAddSpace(attrValue.unit)}<span>(${this.strings.Calculated})</span>` : attrValue.value ? attrValue.unit : "";
      itemModel = this.initItemModel(value, unit, itemModel, storedFirst && isPopup);
    }

    let items: Immutable.List<ListItemModel> = list.items;

    if (!items)
      items = Immutable.List<ListItemModel>();

    if (storedFirst) {
      items = items.push(storedItemModel);
      if (isPopup)
        items = items.push(itemModel);
    }
    else {
      items = items.push(itemModel);
      if (isPopup)
        items = items.push(storedItemModel);
    }

    list = list.setItems(items);

    // Add into cells.
    return list;
  }

  nullAsEmptyStringOrAddSpace(value: string): string {
    if (value == null)
      return "";
    else
      return value + " ";
  }

  initItemModel(value: string, unit: string, itemModel: ListItemModel, isItalic = false): ListItemModel {
    const valueAndUnit = unit ? `${this.nullAsEmptyStringOrAddSpace(value)}${unit}` : value;
    itemModel = itemModel.setTitle(isItalic && valueAndUnit != null ? `<i>${valueAndUnit}</i>` : valueAndUnit);
    itemModel = itemModel.setIsPrintView(this.isPrintView);
    return itemModel;
  }

  openAttributePopup(list: ListModel): void {
    if (!this.globalDataStore.getGlobalData().globalSettings.showCurrentOrStoredAttributes.endsWith("Popup"))
      return;

    let attributeDecorationItem = new Map<number, AttributeDecorationItem>();

    if (list.tag instanceof AttributeDecorationItem)
      attributeDecorationItem.set(list.tag.attributeId, list.tag);

    let popupList = this.getAttributesDetail(+list.id, attributeDecorationItem, true);

    let refElement = this.criteriaComponents.find(x => x.list == list)?.listDiv.nativeElement;

    if (!refElement)
      return;

    let info = <PopperConfig>{
      title: popupList.title,
      subTitle: this.globalDataStore.getGlobalData().globalSettings.showCurrentOrStoredAttributes.startsWith("Current") ? this.strings.StoredData : this.strings.CalculatedData,
      open: true,
      refElement: refElement,
      tag: popupList
    };

    this.popoverService.show(info);
  }

  subscribeDataChange(): void {

    this.confStore.onConfigurationAttributeValuesChange(this.configurationId, this.confSessionId, (propValues) => {

      this.init();
      this.cdr.markForCheck();

    }).unsubscribeOn(this.unsubscribeSubject);
  }

}