import { Inject, Component, Input, ViewEncapsulation } from "@angular/core";
import { Assortment, Product } from "../shared/models";
import { Breakpoints, BreakPointAccessor } from "../../shared/utils";
import { BaseComponent } from "../shared";
import { RouteRedirector } from "../shared/providers";
import { ConfRouteParams } from "../configurator/providers";

// Customization
import { GlobalDataStore } from "../shared/providers/globalData";
import { InputViewModel } from "../../shared/components";
// End Customization

@Component({
  selector: 'product-detail',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './detailComponent.html'
})
export class DetailComponent extends BaseComponent {

  public readonly DEFAULT_IMAGE = "configuration";
  public image: string = "";
  public imageType: string = "svg";
  public isVault: boolean = false;
  public imageSet: string;
  // Customization - This property holds the information for the textbox (input for Quote number)
  quoteNoModel: InputViewModel;
  showQuoteInput: boolean = false;
  // End Customization

  public isProduct = false;

  _assortment: Assortment;
  detailStyle: CSSStyleDeclaration;
  @Input() set product(value: Assortment) {
    this._assortment = value;
    this.isProduct = value instanceof Product;
    this.image = this.hasImage ? this._assortment.imageRelativeUrl : this.DEFAULT_IMAGE;
    this.isVault = this.hasImage;
    this.imageType = this.hasImage ? 'png' : 'svg';
    this.imageSet = this.hasImage ? '' : 'primary-light';

    this.updateStyle();

    // Customization
    this.showQuoteInput = this.globalDataStore.getGlobalData().globalSettings.quotationProductId == this._assortment.longId;
    // End Customization
  };

  constructor(
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(BreakPointAccessor) public breakPointAccessor: BreakPointAccessor,
    // Customization
    public globalDataStore: GlobalDataStore
    // End Customization
  ) {
    super();

    // Customization - Set id, title, width etc for the inpurt control    
    this.quoteNoModel = new InputViewModel();
    this.quoteNoModel.id = "111";
    this.quoteNoModel.name = "Quote #";
    this.quoteNoModel.title = "Quote #";
    this.quoteNoModel.iconWidth = "350px";
    this.quoteNoModel.enableHelp = false;
    // End Customization
  }

  updateStyle(): void {
    let calMinWidth: string;

    if (this.breakPointAccessor.equalOrDown(Breakpoints.md)) {
      calMinWidth = "100%";
    }
    else {
      calMinWidth = "600px";
    }

    this.detailStyle = <CSSStyleDeclaration>{
      maxWidth: calMinWidth
    }

  }

  get hasImage(): boolean {
    return this._assortment.imageRelativeUrl != "";
  }

  onConfigure(): void {
    this.createConfiguration(this._assortment.longId);
  }

  /**
  * Create the configuration
  * @param productId
  */
  createConfiguration(productId: number) {

    // Customization - Read the value from the quote number textbox and forward in routing
    // It wil be used later on when sending request to server for creating configuration
    this.routeRedirector.redirectToEditor(<ConfRouteParams>{ productId: productId, quoteNo: this.quoteNoModel.value });
  }
}