<action-box *ngIf="visible" textLeft="iKea Order" [showHeader]="showHeader">
  <ng-container>    
      <div class="ikea-order justify-content-between" >
        <div *ngIf="isDefaultState" class="row w-100 m-0 " (click)="onCheckOut()" role="button">
          <div class="col-auto py-2 pr-0">
            <co-image width="24" height="24" type="svg" key="checkmark" imageSet="primary"></co-image>
          </div>
          <div class="col text-uppercase py-2 label">
            CHECK OUT
          </div>
        </div>
        
        <div *ngIf="!isDefaultState" class="row w-100 m-0 " >          
          <div class="col text-uppercase py-2 label">
            {{checkedOutText}}
          </div>
        </div>
      </div>    
  </ng-container>
</action-box>