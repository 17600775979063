import { Component, ViewEncapsulation, Inject } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Routing } from "../../shared/route/routeDecorator";
import { PageComponent } from "../shared";


@Routing({ path: 'ikealanding' })
@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './ikeaLandingComponent.html'
})
export class IkeaLandingComponent extends PageComponent {

  constructor(
    @Inject(Router) public router: Router,
    @Inject(ActivatedRoute) public activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onStart(): void {
    let language = this.activatedRoute.snapshot.paramMap.get('language');
    if (language)
      language = ',%22locale%22:%22' + language + '%22';

    let confParams = '';
    let hookUrl = this.activatedRoute.snapshot.paramMap.get('hookUrl');
    let email = this.activatedRoute.snapshot.paramMap.get('email');
    if (hookUrl || email) {
      confParams = ',confparams:{';
      if (hookUrl)
        confParams += '%22HookUrl%22:%22' + hookUrl + '%22';
      if (email)
        confParams += (hookUrl ? ',' : '') + '%22Email%22:%22' + email + '%22';
      confParams += '}';
    }

    location.replace('/configure?json={usertype:%22anonymous%22' + (language ? language : '') + ',productid:19' + confParams + '}');
  }
}
