<nav *ngIf="visible" class="top-header navbar navbar-expand-lg p-0" #topHeader>
  <a class="navbar-brand px-2 px-md-4 mr-0 mr-md-3 ml-2 ml-md-3 align-middle" routerLinkActive="active" href="#/" (click)="navigate('')">
    <!-- Note: max-width is not working for I.E 11 that's why only style.width is applied. -->
    <co-image [type]="logoType" class="logo" [style.width]="maxWidth" [imageSet]="imageSet" key="logo" ></co-image>
  </a>

  <button *ngIf="canViewHeader" class="navbar-toggler navbar-toggler-right collapsed mr-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" #navbarToggler>
    <span class="sr-only">Toggle navigation</span>
    <span class="icon-bar top-bar"></span>
    <span class="icon-bar middle-bar"></span>
    <span class="icon-bar bottom-bar"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" #navbarSupportedContent>
    <ul class="navbar-nav h-100">
      <li class="nav-item" *ngFor="let link of links" routerLinkActive="{{link.routerLinkActive}}">
        <a class="nav-link header-link btn" role="button" href="#/{{link.routerLink}}" (click)="navigate(link.routerLink)">
          <co-image type="{{link.imageType}}" [width]="'24'" [height]="'24'" [imageSet]="iconsImageSet" key="{{link.imageName}}"></co-image>
          <span class="d-block pt-lg-1">{{link.text}}</span>
        </a>
      </li>
    </ul>
  </div>
</nav>