import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivate } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { of, Observable, EMPTY  } from 'rxjs';
import { take, map, catchError, shareReplay } from 'rxjs/operators';
import { RouteRedirector } from "./routeRedirector";

@Injectable()
export class IkeaGuard implements CanActivate {

  public loginUrl = 'haglund/ikea/validate';
  
  constructor(
    @Inject(HttpClient) public httpClient: HttpClient,
    @Inject(ActivatedRoute) public activatedRoute: ActivatedRoute,
    @Inject(Router) public router: Router,
    public routerRedirector: RouteRedirector)
  {}

  public redirect(state: RouterStateSnapshot) {
    this.routerRedirector.redirectToLogin(state.url);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    
    return new Observable<boolean>(observer => {
      this.httpClient.get(this.loginUrl).subscribe(
       response => {
          if (response) {
            observer.next(true);
            observer.complete();
          }
          else {
            observer.next(false);
            observer.complete();
          }
        },
        error => {
          console.log(error);
          observer.next(false);
          observer.complete();
          return of(false);
        }
      );
    });

// Alternative code
/*  return new Observable<boolean>(observer => {
      this.httpClient.get(this.loginUrl+'?username=' + username + '&password=' + password + '&rnd=' + randomNumber).pipe(
        map((response: Response) => {
          console.log(response);
          if (response) {
            observer.next(true);
            observer.complete();
          }
          else {
            observer.next(false);
            observer.complete();
          }
        }),
        catchError(err => {
          console.log(err);
          observer.next(false);
          observer.complete();
          return of(false);
        })
      );
    });
  }

    return this.httpClient.get(this.loginUrl+'?username=' + username + '&password=' + password + '&rnd=' + randomNumber).pipe(
      map((response: Response) => {
        console.log(response);
        if (response)
          return true;
        else
        {
          this.redirect(state);
          return false;
        }
      }),
      catchError(err => {
        console.log(err);
        this.redirect(state);
        return of(false);
      }),
      shareReplay()
      );*/
  }
}
