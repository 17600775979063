import { Inject, Injectable } from "@angular/core";
import * as Immutable from "immutable";
import { BaseEntity } from "../../shared/baseEntity";
import {
    Conf,

    ConfAttachment, ConfAttributeValue, ConfCodeValue, ConfDocumentValue, ConfGraphicsValue, ConfInfo,
    ConfPropertyValue,
    ConfReport, ConfUIItem, ConfValue
} from "../../shared/models";
import { AbstractConfigurationMessage } from "../../shared/models/responses/messages";
import { ReselectorService } from "../../shared/providers/reselectorService";
import { AppState, ConfSessionData } from "../../shared/state";


@Injectable()
export class ConfDataMemorizer {
  
  constructor(
    @Inject(ReselectorService) public reselectorService: ReselectorService    
  ) {

  }

  /**
   * Configuration memorizer   
   */
  public getConfMemorizer(sessionId: number, configurationId: number): () => Conf {
    return this.reselectorService.createMemorizer1((state) => {

      if (!this.hasSessionData(sessionId, state))
        return;

      return state.configurationData.dataBySessionId.get(sessionId.toString()).entitiesByConfId.get(configurationId)
    },
      (input) => {

        if (!input)
          return;
        
        return input.get(configurationId) as Conf
      });
  }
  
  /**
   * Compsite structure memorizer   
   */
  public getCompositeStructureMemorizer(sessionId: number): () => string {

    return this.reselectorService.createMemorizer((state) => {

      let dataBySessionId: Immutable.Map<string, ConfSessionData> = state.configurationData.dataBySessionId;

      if (!this.hasSessionData(sessionId, state))
        return;

      return JSON.stringify(state.configurationData.dataBySessionId.get(sessionId.toString()).compositeStructure.keySeq());
    });

  }

  public getCompositeOrderChangeMemorizer(sessionId: number): () => string {

    return this.reselectorService.createMemorizer((state) => {
      
      if (!this.hasSessionData(sessionId, state))
        return;

      let rootId = state.configurationData.dataBySessionId.get(sessionId.toString()).rootConfId;
      if (!rootId)
        return;

      // System supports one root at this time in composite structure, so only grab the flattened composite Ids for only one root tree.
      let compositeStructureIds: Array<number> = this.getFlattenedCompositeStructureIds(state, rootId, sessionId);

      return JSON.stringify(compositeStructureIds);
    });

  }
  
  public hasSessionData(sessionId: number, state: AppState): boolean {

    return state.configurationData.dataBySessionId.has(sessionId.toString());

  }

  /**
   * ConfInfo memorizer   
   */
  public getConfInfoMemorizer(sessionId: number, configurationId: number): () => ConfInfo {

    return this.reselectorService.createMemorizer((state) => {

      if (!this.hasSessionData(sessionId, state))
        return;

      return state.configurationData.dataBySessionId.get(sessionId.toString()).compositeStructure.get(configurationId);
    });
  }

  getLookupConfValueMemorizer<T>(confSessionId: number, configurationId: number, lookupParamId: number) {

    return this.reselectorService.createMemorizer((state) => {

      if (!this.hasSessionData(confSessionId, state))
        return null;

      let entitiesById: Immutable.Map<number, BaseEntity> = state.configurationData.dataBySessionId.get(confSessionId.toString()).entitiesByConfId.get(configurationId);

      // Get configuration.
      let conf: Conf = entitiesById.get(configurationId) as Conf;

      let uiItem = this.recursivelyFindConfUIItem(conf.uiItems, lookupParamId);
      if (uiItem)
        return JSON.stringify(uiItem.items);

      return null;
    });

  }

  private recursivelyFindConfUIItem(confUIItems: Immutable.List<ConfUIItem>, longId: number): ConfUIItem {

    for (let i = 0; i < confUIItems.size; i++) {
      let confUIItem: ConfUIItem = confUIItems.get(i);
      let uiItemName = confUIItem.itemName;

      if (confUIItem.id == longId)
        return confUIItem;
      
        let result = this.recursivelyFindConfUIItem(confUIItem.items, longId);
        if (result)
          return result;      
    }

    return null;
  }

  /**
  * Configuration value memorizer   
  */
  public getConfValueMemorizer<T extends BaseEntity>(sessionId: number, configurationId: number, confValueId: number, currentValueFunc?: () => any): () => T {

    return this.reselectorService.createMemorizer2((state) => {

      if (!this.hasSessionData(sessionId, state))
        return null;

      let entitiesByConfId = state.configurationData.dataBySessionId.get(sessionId.toString()).entitiesByConfId;

      if (!entitiesByConfId.has(+configurationId))
        return null;

      return this.getEntity<T>(state, sessionId, configurationId, confValueId);
    },
      (state) => {
        if (currentValueFunc)
          return currentValueFunc();

        return null;
      },
      (state) => state
    );
  }

  public getConfValuesMemorizer(sessionId: number, configurationId: number): () => Immutable.List<ConfValue> {

    return this.reselectorService.createMemorizer((state) => {

      if (!this.hasSessionData(sessionId, state))
        return;

      let entitiesByConfId = state.configurationData.dataBySessionId.get(sessionId.toString()).entitiesByConfId;

      if (!entitiesByConfId.has(+configurationId))
        return;

      let conf = this.getEntity<Conf>(state, sessionId, configurationId, configurationId);

      if (!conf.confValues)
        return Immutable.List<ConfValue>();

      let result: ConfValue[] = [];
      conf.confValues.map(id => { this.getEntity<ConfValue>(state, sessionId, configurationId, id) }).toArray();      
      return Immutable.List<ConfValue>(result);

    });
  }

  public getConfGraphicsValueMemorizer(sessionId: number, configurationId: number, graphicId: number): () => string {

    return this.reselectorService.createMemorizer((state) => {

      if (!this.hasSessionData(sessionId, state))
        return null;

      let entitiesByConfId = state.configurationData.dataBySessionId.get(sessionId.toString()).entitiesByConfId;

      if (!entitiesByConfId.has(+configurationId))
        return null;

      let graphicsValue = this.getEntity<ConfGraphicsValue>(state, sessionId, configurationId, graphicId);

      // Remove the GUIDs from the comparison
      if (graphicsValue)
        return graphicsValue.svg.replace(/(symbol id|use xlink:href)="([^"]*)"/g, '');

      return null;
    });
  }

  public getAttributeValuesMemorizer(sessionId: number, configurationId: number): () => Immutable.List<ConfAttributeValue> {

    return this.reselectorService.createMemorizer((state) => {

      if (!this.hasSessionData(sessionId, state))
        return;

      let entitiesByConfId = state.configurationData.dataBySessionId.get(sessionId.toString()).entitiesByConfId;

      if (!entitiesByConfId.has(+configurationId))
        return;

      let conf = this.getEntity<Conf>(state, sessionId, configurationId, configurationId);

      if (!conf.attributeValues)
        return Immutable.List<ConfAttributeValue>();

      let result: ConfAttributeValue[] = conf.attributeValues.map(id => this.getEntity<ConfAttributeValue>(state, sessionId, configurationId, id)).toArray();

      return Immutable.List<ConfAttributeValue>(result);

    });
  }

  /**
   * Decoration Items memorizer. e.g Attribute items, Code items.
   * @param sessionId
   * @param configurationId
   * @param entityIds
   */
  public getDecorationItemsMemorizer(sessionId: number, configurationId: number, entityIds: Array<number>): () => Map<number, BaseEntity> {

    return this.reselectorService.createMemorizer((state) => {

      if (!this.hasSessionData(sessionId, state))
        return;

      let valueByItemId: Map<number, BaseEntity> = new Map<number, BaseEntity>();

      let confValByEntityId = state.configurationData.dataBySessionId.get(sessionId.toString()).entitiesByConfId.get(configurationId);

      for (let entId of entityIds)
        valueByItemId.set(entId, confValByEntityId.get(entId));

      return valueByItemId;
    });

  }

  /**
   * Configuration properties memorizer   
   */
  getConfPropertyMemorizer(sessionId: number, configurationId: number): () => Immutable.List<ConfPropertyValue> {

    return this.reselectorService.createMemorizer((state) => {

      if (!this.hasSessionData(sessionId, state))
        return null;

      let entitiesById: Immutable.Map<number, BaseEntity> = state.configurationData.dataBySessionId.get(sessionId.toString()).entitiesByConfId.get(configurationId);

      // Get configuration.
      let conf: Conf = entitiesById.get(configurationId) as Conf;

      return conf.confPropertyValues;
    });

  }

  getAttachmentsMemorizer(sessionId: number, configurationId: number): () => Immutable.List<ConfAttachment> {

    return this.reselectorService.createMemorizer((state) => {

      if (!this.hasSessionData(sessionId, state))
        return null;

      let entitiesById: Immutable.Map<number, BaseEntity> = state.configurationData.dataBySessionId.get(sessionId.toString()).entitiesByConfId.get(configurationId);

      // Get configuration.
      let conf: Conf = entitiesById.get(configurationId) as Conf;

      return conf.attachments;
    });

  }

  getDocumentValueMemorizer(sessionId: number, configurationId: number): () => Immutable.List<ConfDocumentValue> {

    return this.reselectorService.createMemorizer((state) => {

      if (!this.hasSessionData(sessionId, state))
        return null;

      let entitiesById: Immutable.Map<number, BaseEntity> = state.configurationData.dataBySessionId.get(sessionId.toString()).entitiesByConfId.get(configurationId);

      // Get configuration.
      let conf: Conf = entitiesById.get(configurationId) as Conf;

      return conf.documentValues;
    }, false);

  }

  getCodeValuesMemorizer(sessionId: number, configurationId: number): () => Immutable.List<string> {

    return this.reselectorService.createMemorizer(
      (state) => {

        if (!this.hasSessionData(sessionId, state))
          return null;

        // Get configuration.
        let conf: Conf = this.getEntity<Conf>(state, sessionId, configurationId, configurationId);

        if (!conf.codeValues)
          return null;

        return conf.codeValues.map(id => this.getEntity<ConfCodeValue>(state, sessionId, configurationId, id).value).toList();
      }, false);
  }

  getConfReportsMemorizer(sessionId: number, configurationId: number): () => Immutable.List<ConfReport> {

    return this.reselectorService.createMemorizer((state) => {

      if (!this.hasSessionData(sessionId, state))
        return null;

      let entitiesById: Immutable.Map<number, BaseEntity> = state.configurationData.dataBySessionId.get(sessionId.toString()).entitiesByConfId.get(configurationId);

      // Get configuration.
      let conf: Conf = entitiesById.get(configurationId) as Conf;

      return conf.reports;
    });

  }

  public getEntity<T extends BaseEntity>(state: AppState, sessionId: number, configurationId: number, entityId: number): T {
    return state.configurationData.dataBySessionId.get(sessionId.toString()).entitiesByConfId.get(+configurationId).get(+entityId) as T;
  }

  public getMessagesMemorizer<T extends AbstractConfigurationMessage>(className: string, sessionId: number, ignoreNull: boolean = true): () => Immutable.List<T> {

    return this.reselectorService.createMemorizer((state) => {
      
      // Note! Don't listen the messages until configurationData is found. Changing the view (e.g summary -> editor), <configurationActionCreator.dispatchClearConfiguratorSession> makes the session data empty
      // and system throws the error if components listen the change out of the view's scope..

      if (!sessionId || !state.configurationData.dataBySessionId.has(sessionId.toString()))
        return null;

      let messages = state.configurationData.dataBySessionId.get(sessionId.toString()).messages;
      if (messages && messages.size > 0)
        return messages.get(className) as Immutable.List<T>;

      return null;
    }, ignoreNull);
  }

  public getFlattenedCompositeStructureIds(state: AppState, rootId: number, confSessionId: number): Array<number> {

    let compositeData: Immutable.Map<number, ConfInfo> = state.configurationData.dataBySessionId.get(confSessionId.toString()).compositeStructure;
        
    // Get root confInfo
    let rootConfInfo: ConfInfo = compositeData.get(rootId);

    let confInfoList: Array<number> = [];

    let addConfInfos = (parent: ConfInfo) => {

      parent.children.forEach(childConfId => {

        let childConfInfo: ConfInfo = compositeData.get(childConfId);
        confInfoList.push(childConfInfo.longId);

        // Make recursive call.
        addConfInfos(childConfInfo);

      });
      
    }

    confInfoList.push(rootConfInfo.longId);
    addConfInfos(rootConfInfo);

    return confInfoList;
  }

  //public getAllMessagesMemorizer(sessionId: number, configurationId: number): () => ReselectorResult<Array<AbstractMessage>> {
  //  return this.reselectorService.createMemorizer((state) => state.configurationData.dataBySessionId.get(sessionId.toString()).messages);
  //}

}