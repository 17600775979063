// Customization
import { InputViewModel } from "../../../../shared/components/shared/models";
// End customization

export class PriceReportRowView {

  cells: PriceReportCellView[] = [];

  // Css classes
  classes: string;

  id: number;

  tag?: any;

  // If any cell contains editable data
  allowEdit: boolean = false;

  selected: boolean = false;

  // Customization
  priceTextCheckboxView: InputViewModel;
  priceValueCheckboxView: InputViewModel;
  showCheckboxes: boolean = false;
  // End customization

}

export class PriceReportCellView {

  contents: string;

  size: number;

  classes: string;

  level: number;

  isEditorCell?: boolean = true;

  isEditorReadOnly?: boolean = false;
}