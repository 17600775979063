<style>
  @media (min-height: 800px) and (min-width: 700px) {
    .ikea-landing {
      align-items: center;
    }
  }
  .ikea-landing {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .ikea-box {
    background-color: #fff;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 10px;
    border: solid 1px #e3e3e3;
    min-width: 420px;
    width: 800px;
  }
  .ikea-header {
    font-size: 22px;
    color: #444;
    margin-bottom:4px;
  }
  .ikea-normal {
    font-size: 1rem;
    color: #666;
    line-height:1.4em;
  }
  .ikea-normal b {
    font-weight: 500;
  }
</style>
<div class="ikea-landing">
  <div class="ikea-box">
    <h2 class="ikea-header">
      WELCOME
    </h2>
    <p class="ikea-normal">
      Welcome to Haglunds configurator/web shop for:
    </p>
    <ul>
      <li>Gastrome Refrigerator</li>
      <li>Cold Display Vitrine</li>
      <li>Undercounter Refrigerator</li>
    </ul>
    <p class="ikea-normal">
      Before continuing please, read or print these instructions. If you have any questions you are welcome to contact Haglund Industri by phone +46 321 29940 or email sales@haglundindustri.se.
    </p>
    <p class="ikea-normal">
      1. Click on <b>START</b> to enter the configurator.
    </p>
    <p class="ikea-normal">
      2. Select your <b>Country</b>, <b>Location</b> and enter other order information.
    </p>
    <p class="ikea-normal">
      3. Add products by moving the mouse over the <b>IKEA Order-xxxxx</b> field to the left and press the <b>+ button</b>.
    </p>
    <p class="ikea-normal">
      4. Select the product you would like to add to the shopping cart.
    </p>
    <p class="ikea-normal">
      5. Configure the product so that it fits your requirements and enter the quantity you would like to order of each product.
    </p>
    <p class="ikea-normal">
      6. Add more products by repeating step 3-5.
    </p>
    <p class="ikea-normal">
      7. Verify that everything is correct <u>before</u> pressing <b>SAVE & CLOSE</b>. After this step you cannot make changes in this shopping cart, but need to restart from your eProcurement system and IBX search engine.
    </p>
    <p class="ikea-normal">
      8. Press <b>SAVE & CLOSE</b>.
    </p>
    <p class="ikea-normal">
      9. Click on the <b>IKEA Quote</b> report for a final verification of the order.
    </p>
    <p class="ikea-normal">
      10. Press <b>CHECK OUT</b> to send the shopping cart and return to your eProcurement system and IBX search engine.
    </p>

    <button type="button" class="btn btn-primary text-uppercase" (click)="onStart()">Start</button>
  </div>
</div>