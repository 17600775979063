import { Component, Inject, Input } from "@angular/core";
import { BaseActionBoxComponent } from "../..";
import { ConfiguratorStore, ConfPageSessionService } from "../../../configurator/providers";
import { Conf, State, ConfHistory, StateCommand } from "../../models";
import { GlobalDataStore } from "../../providers/globalData";
import { StateStore } from "../states/stateStore";
import { ModelFactory } from "../../providers/modelFactory";
import * as Immutable from "immutable";

@Component({
  selector: 'ikea-order-action-box',
  templateUrl: './ikeaOrderActionBoxComponent.html',
  providers: [StateStore]
})
export class IkeaOrderActionBoxComponent extends BaseActionBoxComponent {

  isDefaultState: boolean = true;
  checkedOutText: string;

  constructor(
    @Inject(ConfiguratorStore) public configuratorStore: ConfiguratorStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(StateStore) public stateStore: StateStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ModelFactory) public modelFactory: ModelFactory
  ) {
    super(configuratorStore);
  }

  ngOnInit() {
    super.ngOnInit();


    this.configuratorStore.onConfigurationChange(this.configurationId, this.confSessionId, (conf: Conf) => {

      this.initialize();

    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public setup(): void {

    let stateRefToObj = this.confPageSessionService.activeConfiguration.state;

    // Grab state object from cache.
    let state: State = this.globalDataStore.getGlobalData().entities.get(stateRefToObj.longId) as State;

    // Note! state.defaultState returns string but from C# DTO the return type is bool, this is the bug in 3.1.0.7, It would be fixed in 3.1.1.
    this.isDefaultState = state.defaultState.toString() == "true";

    if (!this.isDefaultState) {

      // Get default state, where the promotion is made from.
      let defaultState: State = this.conf.histories.map(x => this.globalDataStore.getGlobalData().entities.get(x.fromState.longId) as State).filter(state => state.defaultState.toString() == "true").first();

      // Grab the conf history for default state.
      let confHistory: ConfHistory = this.conf.histories.filter(x => x.fromState.longId == defaultState.longId).first();
      this.checkedOutText = `Checked out ${confHistory.creationDate}`;
    }

  }

  public shouldBeVisible(): boolean {
    return true;
  }

  public onCheckOut(): void {

    let map: Immutable.Map<string, string> = Immutable.Map<string, string>();
    map = map.set("ikeaPromote", "true");

    let command: StateCommand = this.modelFactory.createRequestOrCommand<StateCommand>(StateCommand.name, { configurationId: this.configurationId, extraArgs: map });
    this.stateStore.stateChange(command);

  }

}
