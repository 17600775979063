import { Inject, Component } from "@angular/core";
import { ConfigurationSessionManager, ConfiguratorStore, ConfPageSessionService } from "../providers";
import * as Immutable from 'immutable';
import { SummaryBaseComponent } from "./summaryBaseComponent";
import {
  ConfUIItem, CodeDisplayStyles, CodeDecoration, GroupConfUIItem, DocumentsDecoration
  // Customization
  ,ConfInfo, User, State
  // End of customization
} from "../../shared/models";
import { ProductDataStore } from "../../shared/providers/productData";
import { CodeValueModelBuilder } from "../../shared/components/code/providers/codeValueModelBuilder";
import { CodeDecorationValuesModel } from "../../shared/components/code/codeDecorationValueModel";
import { GlobalDataStore } from "../../shared/providers/globalData/globalDataStore";
import { VisualObjectUIDataService } from "../shared/visualObjectUIDataService";

// Customization
import { AccountDataStore } from "../../shared/providers/accountData";
// End of customization

export enum ActionBoxNames {
  Operations = 'operations',
  States = 'states',
  Revisions = 'revisions',
  Reports = 'reports',
  Code = 'code',
  Documents = 'documents',
  Graphics = 'graphics',
  Attachments = 'attachments'
}

@Component({
  selector: 'summary-action-boxes',
  templateUrl: './summaryActionsBoxesComponent.html',
  providers: [VisualObjectUIDataService]
})
export class SummaryActionsBoxesComponent extends SummaryBaseComponent {
  actionBoxNames = ActionBoxNames;

  codeUIItems: Immutable.List<ConfUIItem> = Immutable.List<ConfUIItem>();
  documentsUIItems: Immutable.List<ConfUIItem> = Immutable.List<ConfUIItem>();
  orderList: Immutable.List<string> = Immutable.List<string>();

  // Customization
  showIkeaOrder: boolean = false;
  // End of customization

  constructor(@Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(CodeValueModelBuilder) public builder: CodeValueModelBuilder,
    // Customization
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    // End of customization
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore) {
    super(confPageSessionService);
  }

  // List of each decoration values.
  decorationValuesModels: Immutable.List<CodeDecorationValuesModel> = Immutable.List<CodeDecorationValuesModel>();

  onDataReady(): void {
    // Customization
    this.globalDataStore.getEntities<State>(State.name).subscribe((storeResponse) => {

      let rootConfInfo: ConfInfo = this.confStore.getRootConfInfo(this.confSessionId);

      let user: User = this.accountStore.getUser();
      let hasAnonymousAccess: boolean = !user || user.systemAuthorization.hasAnonymousAccess;

      // Grab state object from cache.    
      this.showIkeaOrder = rootConfInfo.productId == 64424509459 && hasAnonymousAccess;

    }).unsubscribeOn(this.unsubscribeSubject);
    // End of customization
    
    this.orderList = this.globalDataStore.globalSettings.summaryActionBoxesDisplayOrder;

    // TODO: This part requires more improvement.
    const uiItems: Array<ConfUIItem> = this.confPageSessionService.activeConfiguration.uiItems.filter(uiItem => uiItem.itemName == CodeDecoration.name).toArray();

    if (this.globalDataStore.globalSettings.displayDocumentsInOperationsColumn)
      this.documentsUIItems = this.confPageSessionService.activeConfiguration.uiItems.filter(uiItem => uiItem.itemName == DocumentsDecoration.name).toList();

    const codeFilteredStyles = [CodeDisplayStyles.DownloadLink, CodeDisplayStyles.Popup];

    this.decorationValuesModels = this.decorationValuesModels.clear();

    uiItems.forEach(x => {

      // Note! Grouping is not supported yet for action boxes. We need more work on this part.
      let ValuesModels = this.builder.build(x, false, codeFilteredStyles).toArray();
      this.decorationValuesModels = this.decorationValuesModels.push(...ValuesModels);


    });

      
    


  }



}