<ng-container *ngIf="report != null">

  <div *ngIf="showErrorMessage" class="price-report-warnings cleared-prices mt-3">
    <div *ngIf="report.warnings && report.warnings.size > 0">      
      <ul>
        <li *ngFor="let warning of report.warnings">
          {{warning}}
        </li>
      </ul>
    </div>

    <div *ngIf="clearedPrices && clearedPrices.size > 0" class="has-error">
      <div class="px-4 error-message">{{strings.PriceItemValueCleared}}</div>
      <div *ngFor="let entity of clearedPrices" class="row px-4 pt-2">
        <div class="col">{{entity.key}}</div>
        <div class="col text-right">{{entity.value}}</div>
      </div>
    </div>
  </div>

  <gridview [class.action-mode]="isGridViewInActionMode" width="100%" >
    <gridview-header>
      <header-columntemplate size="2" classes="header">
        {{strings.Description}}
      </header-columntemplate>
      <header-columntemplate classes="header text-right pr-3">
        <span class="contents">{{strings.Amount}}</span>
      </header-columntemplate>
    </gridview-header>
    <gridview-row *ngFor="let rowView of rowViews"
                  (click)="rowView.allowEdit && onPriceRowClick(rowView)"
                  [classes]="rowView.classes"
                  [class.row-selected]="rowView.selected"
                  [attr.id]="rowView.id"
                  class="report-row">
      <!-- Customization -->
      <columntemplate *ngFor="let cell of rowView.cells; let iindex = index" [classes]="cell.classes">
        <check-box *ngIf="rowView.showCheckboxes && iindex == 0" type="standard" class="price-text-checkbox" [view]="rowView.priceTextCheckboxView" (onValueChange)="onTextSelectionChanged($event, rowView)"></check-box>
        <!-- End customization -->
        <span *ngIf="cell.level > 0" [style.width.px]="cell.level*15" class="indent">&nbsp;</span>

        <span *ngIf="!cell.isEditorCell">{{cell.contents}}</span>

        <price-editor *ngIf="cell.isEditorCell" class="contents"
                      [isReadOnly]="cell.isEditorReadOnly"
                      [selected]="rowView.selected"                      
                      [row]="rowView"
                      (onSubmit)="onSubmitClick($event)"
                      (onCancel)="onCancelEdit(rowView)"                           
                      ></price-editor>
        <!-- Customization -->
        <check-box *ngIf="rowView.showCheckboxes && iindex == rowView.cells.length - 1" type="standard" class="price-value-checkbox" [view]="rowView.priceValueCheckboxView" (onValueChange)="onValueSelectionChanged($event, rowView)"></check-box>
        <!-- End customization -->
      </columntemplate>
    </gridview-row>

    <ng-container *ngIf="childrenRowViews.length > 0">
      <br />

      <gridview-header>
        <header-columntemplate classes="header">
          <span class="ellipsis">{{strings.Child}}</span>
        </header-columntemplate>
        <header-columntemplate classes="header pr-4 child-report-header {{CHILD_COLUMN_SIZE_LG}}" [size]="CHILD_COLUMN_SIZE">
          <span class="ellipsis">{{strings.Quantity}}</span>
        </header-columntemplate>
        <header-columntemplate classes="header pr-4 child-report-header {{CHILD_COLUMN_SIZE_LG}}" [size]="CHILD_COLUMN_SIZE">
          <span class="ellipsis">{{strings.UnitPrice}}</span>
        </header-columntemplate>
        <header-columntemplate classes="header pr-4 child-report-header {{CHILD_COLUMN_SIZE_LG}}" [size]="CHILD_COLUMN_SIZE">
          <span class="ellipsis">{{strings.Price}}</span>
        </header-columntemplate>
      </gridview-header>

      <gridview-row *ngFor="let rowView of childrenRowViews"
                    [classes]="rowView.classes"
                    [attr.id]="rowView.id"
                    [class.row-selected]="rowView.selected"
                    class="report-row"
                    (click)="childReportClicked.emit(rowView.id)">
        <columntemplate *ngFor="let cell of rowView.cells" [classes]="cell.classes" [size]="cell.size">
          <span *ngIf="cell.level > 0" [style.width.px]="cell.level*15" class="indent">&nbsp;</span>

          <span *ngIf="!cell.isEditorCell">{{cell.contents}}</span>

          <price-editor *ngIf="cell.isEditorCell" class="contents"
                        [row]="rowView"
                        [isReadOnly]="cell.isEditorReadOnly"
                        (onSubmit)="onSubmitClick($event)"
                        (onCancel)="onCancelEdit(rowView)"                        
                        ></price-editor>

        </columntemplate>
      </gridview-row>
    </ng-container>

    <br />
    <gridview-row [classes]="grandTotalView.classes">
      <columntemplate *ngFor="let cell of grandTotalView.cells" [classes]="cell.classes">
        <span *ngIf="cell.level > 0" [style.width.px]="cell.level*15" class="indent">&nbsp;</span>
        <span class="contents">{{cell.contents}}</span>
      </columntemplate>
    </gridview-row>
  </gridview>

  <br />
</ng-container>

