<action-box *ngIf="visible" class="detail-item {{extraStyles}}" iconLeft="attributeDecoration" textLeft="{{detailTitle}}" >
  <gridview *ngIf="contentVisible">
    <gridview-row *ngFor="let row of rows" classes="row-data">
      <!-- Customization (modified) -->
      <columntemplate *ngFor="let list of row.cells" classes="min-width col-12 {{detailTitle != 'Shopping cart' ? 'col-sm-6' : ''}}" [class.p-0]="!list.items">
      <!-- End of customization -->
        <list [list]="list" (mouseenter)="openAttributePopup(list)" (click)="openAttributePopup(list)"></list>
      </columntemplate>
    </gridview-row>
  </gridview>
  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>