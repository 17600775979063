import { Inject, Component, Input, Optional, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import * as Immutable from 'immutable';

import { BaseActionBoxComponent } from "../..";
import { RouteRedirector, RouteNames, ApiMessageProvider } from "../../providers";
import { ConfigurationSessionManager } from "../../../configurator/providers/configurationSessionManager";
import { ConfiguratorStore, ConfRouteParams, PopupIdentifiers } from "../../../configurator/providers";
import { PageStore } from "../../providers/page";
import { RequestViews, ConfDeleteMessage, ChangeOwnershipMessage, Conf } from "../../models";
import { ConfMessageProvider } from "../../../configurator/providers/confMessageProvider";
import { NotificationService, NotificationInfo, NotificationType, PopupService, MessageBoxComponent, MessageBoxConfig } from "../../../../shared/components";
import { ChangeOwnershipPopupComponent } from "./changeOwnershipPopupComponent";
import { SearchDataStore } from "../../providers/searchData";
import { ManagedSubscription } from "../../../../shared/managedSubscription";
import { SummaryPrintComponent } from "../print/summaryPrintComponent";
import { ImageSets } from "../../../../shared/utils/imageSets";
import { HttpService } from "../../../../shared/providers/httpService";
import { GlobalDataStore } from "../../providers/globalData";
import { IntegrationSettings } from "../../models/responses/messages/integrationSettings";

const ITEM_NAMES = {
  Edit: "edit",
  Recover: "recover",
  Reuse: "reuse",
  Revise: "revise",
  Delete: "delete",
  Ownership: "ownership",
  Print: "print",
  EmailLink: "emaillink",

  // MsCrm ->
  OpenInDynamics: "openInDynamics"
  // MsCrm <-
}

@Component({
  selector: 'operations-action-box',
  templateUrl: './operationsActionBoxComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperationsActionBoxComponent extends BaseActionBoxComponent {

  protected changeOwnershipMessage: ManagedSubscription;
  protected confChangeSubscription: ManagedSubscription;

  public items: Immutable.List<any> = Immutable.List<any>();

  public isSummary = false;

  @ViewChild(MessageBoxComponent)
  public confirmDeleteMessageBox: MessageBoxComponent;

  @ViewChild(ChangeOwnershipPopupComponent)
  public changeOwnershipPopup: ChangeOwnershipPopupComponent;

  @ViewChild(SummaryPrintComponent)
  public summaryPrint: SummaryPrintComponent;

  // MsCrm
  public isInMsCrmMode: boolean = false;
  public integrationSettings: IntegrationSettings;

  constructor(
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(ConfiguratorStore) public configuratorStore: ConfiguratorStore,
    @Inject(SearchDataStore) public searchDataStore: SearchDataStore,
    @Inject(HttpService) public httpService: HttpService,
    @Inject(ApiMessageProvider) public apiMessageProvider: ApiMessageProvider,
    public globalDataStore: GlobalDataStore,
    public confMessageProvider: ConfMessageProvider,
    public notificationService: NotificationService,
    public pageStore: PageStore,
    public cd: ChangeDetectorRef,
    public popupService: PopupService,
  ) {
    super(configuratorStore);
  }

  ngOnInit(): void {
    this.isSummary = this.pageStore.activeRouteName === RouteNames.Summary;

    // MsCrm
    this.isInMsCrmMode = this.globalDataStore.getGlobalData().globalSettings.isInMsCrmMode;

    super.ngOnInit();
  }

  public setup(): void {

    if (!this.configurationId)
      return;

    this.unsubscribe();
    this.confChangeSubscription = this.configuratorStore.onConfigurationChange(this.configurationId, this.confSessionId, (conf: Conf) => {

      this.conf = conf;
      this.init();
      this.listenOnIntegrationMessage();
      this.cd.markForCheck();

    });

    // MsCrm
  }


  public init() {

    this.items = this.items.clear();

    if (!this.conf)
      return;

    let confAuth = this.conf.authorization;
    let buttonStyles = "";

    if (confAuth.isReviseOperationDisallowed)
      buttonStyles = "disabled-button";

    // Customization - modified
    if (confAuth.canEdit && !this.integrationSettings?.isRunningInIframe)
      this.items = this.items.push({
        name: ITEM_NAMES.Edit,
        text: (confAuth.canSave ? this.strings.Edit : this.strings.View),
        styles: ""
      });
    // End of customization

    if (confAuth.canRecover && !this.integrationSettings?.isRunningInIframe)
      this.items = this.items.push({
        name: ITEM_NAMES.Recover,
        text: this.strings.Recover,
        styles: ""
      });

    if (confAuth.canReuse && !this.integrationSettings?.isRunningInIframe)
      this.items = this.items.push({
        name: ITEM_NAMES.Reuse,
        text: this.strings.Reuse,
        styles: ""
      });

    if (confAuth.canRevise && !this.integrationSettings?.isRunningInIframe)
      this.items = this.items.push({
        name: ITEM_NAMES.Revise,
        text: this.strings.Revise,
        styles: buttonStyles
      });

    if (confAuth.canDelete && !this.integrationSettings?.isRunningInIframe)
      this.items = this.items.push({
        name: ITEM_NAMES.Delete,
        text: this.strings.Delete,
        styles: ""
      });

    if (confAuth.canChangeOwnership && this.isSummary)
      this.items = this.items.push({
        name: ITEM_NAMES.Ownership,
        text: this.strings.ChangeOwnership,
        styles: ""
      });

    if (confAuth.canPrint && this.isSummary)
      this.items = this.items.push({
        name: ITEM_NAMES.Print,
        text: this.strings.Print,
        styles: ""
      });

    if (confAuth.canEmailLink)
      this.items = this.items.push({
        name: ITEM_NAMES.EmailLink,
        text: this.strings.EmailThisConfiguration,
        styles: ""
      });

    // MsCrm ->
    if (this.isInMsCrmMode && !this.integrationSettings?.isRunningInIframe)
      this.items = this.items.push({
        name: ITEM_NAMES.OpenInDynamics,
        text: "Open in dynamics",
        styles: ""
      });
    // MsCrm <-
  }

  public shouldBeVisible(): boolean {
    return this.items && this.items.size > 0;
  }

  onItemClick(name: string): void {
    switch (name) {
      case ITEM_NAMES.Edit:
      case ITEM_NAMES.Recover:
        this.editConfiguration();
        break;

      case ITEM_NAMES.Reuse:
        this.reuseConfiguration();
        break;

      case ITEM_NAMES.Revise:
        this.reviseConfiguration();
        break;

      case ITEM_NAMES.Delete:
        this.deleteConfiguration();
        break;

      case ITEM_NAMES.Ownership:
        this.changeOwnership();
        break;

      case ITEM_NAMES.EmailLink:
        this.emailLink();
        break;

      case ITEM_NAMES.Print:
        this.summaryPrint.show();
        break;


      //MsCrm ->
      case ITEM_NAMES.OpenInDynamics:
        {
          let url = '/mscrm/getQuoteUrl?confid=' + this.configurationId + '#';
          this.httpService.get(url).subscribe(data => {
            if (data.newtab)
              window.location.href = data.url;
            else
              window.open(data.url, '_blank').focus();
          });
          break;
        }
      //MsCrm <-
    }
  }

  public deleteConfiguration() {

    this.confMessageProvider.onMessagesRequest<ConfDeleteMessage>(this.confSessionId, ConfDeleteMessage.name, {
      next: (messages: Immutable.List<ConfDeleteMessage>): void => {
        let message = messages.first();

        this.notificationService.notify(<NotificationInfo>{
          title: message.title || message.displayStyle ? message.title : message.success ? this.strings.Success : this.strings.Error,
          message: message.message ? message.message : message.success ? this.strings.ConfigurationIsDeleted : message.message,
          type: message.displayStyle ? message.displayStyle : message.success ? NotificationType.Info : NotificationType.Error,
          selfClose: true
        });

        if (message.success) {
          if (this.pageStore.getActiveClientType() == RequestViews.Start) {

            this.unblockUI();

            // If configuration is deleted on start page then remove all connected ConfDataSessions
            // This will update all configuration search results on start page
            let confSessionData = this.configuratorStore.getConfSessionData(this.confSessionId);

            // If we simulate slow 3G network speed and make a double click then it throws null refernce
            // exception. The bug is very difficult to reproduce but we need more investigation to find the
            // root cause. As block-ui is already preventing the subscequent actions...
            if (confSessionData && confSessionData.rootConfId)
              this.configuratorStore.removeConfiguratorSessions(confSessionData.rootConfId);
          }
          else
            this.routeRedirector.redirectToStart();
        }
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);

    let confInfo = this.configuratorStore.getConfInfo(this.configurationId, this.confSessionId);
    if (confInfo.rootId !== this.configurationId)
      confInfo = this.configuratorStore.getConfInfo(confInfo.rootId, this.confSessionId)

    let info: MessageBoxConfig<[number, number]> = <MessageBoxConfig<[number, number]>>{
      headerText: this.strings.AreYouSureYouWantToDelete,
      caption: confInfo.allowedChildProductIds.size > 0 ? this.strings.ConfigurationIncludingChildren : this.strings.Configuration,
      description: confInfo.text,
      icon: "error",
      tag: [this.configurationId, this.confSessionId]
    }

    this.confirmDeleteMessageBox.show(info);

    //this.configuratorStore.deleteConfiguration(this.configurationId, this.confSessionId);


    //this.deleteConfigurationPopup.show(this.configurationId, this.confSessionId);
  }

  deleteConf([configurationId, confSessionId]) {

    this.plainBlockUI();
    this.configuratorStore.deleteConfiguration(configurationId, confSessionId, this.pageStore.getActiveClientType());

  }

  public editConfiguration() {
    // In case of summary re-use the session id. In other cases like start or search page we dont want to re-use session
    //if (this.pageStore.getActiveClientType() == RequestViews.Summary)
    this.routeRedirector.redirectToEditor(<ConfRouteParams>{ confSessionId: this.confSessionId, id: this.configurationId });
    //else
    //  this.routeRedirector.redirectToEditor(<ConfRouteParams>{ id: this.configurationId });
  }

  /**
   * Reuse the configuration by going to the redirect component -> old configuration component is destroyed -> redirect to the new configuration and set it up.
   */
  public reuseConfiguration() {    

    // Customization -- display input dialog to read quote no. Applies to quote product only
    let quoteNo = null;

    let rootConfInfo = this.configuratorStore.getRootConfInfo(this.confSessionId);
    if (rootConfInfo) {
      let showInputForQuoteId = this.configuratorStore.globalDataStore.getGlobalData().globalSettings.quotationProductId == rootConfInfo.productId;
      if (showInputForQuoteId) {
        quoteNo = prompt("Provide quote number", "");
      }
    }
    this.routeRedirector.redirectToEditor(<ConfRouteParams>{ reuse: this.configurationId, quoteNo: quoteNo });    
    // End Customization
  }

  /**
   * Revise the configuration by going to the redirect component -> old configuration component is destroyed -> redirect to the new configuration and set it up.
   */
  public reviseConfiguration() {
    this.routeRedirector.redirectToEditor(<ConfRouteParams>{ revise: this.configurationId });
  }

  public changeOwnership() {
    this.unsubscribe();

    this.changeOwnershipMessage = this.confMessageProvider.onMessagesRequest<ChangeOwnershipMessage>(this.confSessionId, ChangeOwnershipMessage.name, {
      next: (messages: Immutable.List<ChangeOwnershipMessage>): void => {
        let message = messages.first();

        this.notificationService.notify(<NotificationInfo>{
          title: message.success ? this.strings.Success : this.strings.Error,
          message: message.success ? "Successfully changed the ownership" /*TODO localize*/ : message.message,
          type: message.success ? NotificationType.Success : NotificationType.Error,
          selfClose: true
        });
      },
      listenNewEventsOnly: true
    });

    this.changeOwnershipPopup.show(this.configurationId, this.confSessionId);
  }

  protected emailLink() {
    this.popupService.open(PopupIdentifiers.AnonymousDialog);
  }

  unsubscribe() {

    if (this.changeOwnershipMessage)
      this.changeOwnershipMessage.unsubscribe();

    if (this.confChangeSubscription)
      this.confChangeSubscription.unsubscribe();

  }

  // MSCRM begin
  public listenOnIntegrationMessage() {
    this.apiMessageProvider.onMessages<IntegrationSettings>(IntegrationSettings.name,
      {
        next: (messages) => {
          messages.forEach(apiMessage => {
            this.integrationSettings = apiMessage;
            this.init();
          });
        }
      });
  }

  // MSCRM end

  ngOnDestroy() {
    this.unsubscribe();

    super.ngOnDestroy();
  }
}