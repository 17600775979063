import { Component, Input, SimpleChanges } from "@angular/core";
import { InputViewModel } from "../shared/models/inputViewModel";
import { BaseInputComponent } from "../shared/baseInputComponent";

@Component({
  selector: "dropdown",
  templateUrl: './genericDropdownGroup.html',
})
export class GenericDropdownGroup extends BaseInputComponent {

  @Input()
  itemsView: InputViewModel[] = [];

  @Input()
  menuHeight: string;

  @Input()
  collapseIcon: string;

  @Input()
  expandIcon: string;

  /**
    True to auto add a empty value at the top of the items.
  */
  @Input()
  public showEmptyValue: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['itemsView'] || changes['view'])
      this.setDefaultValue();
  }

  public setDefaultValue(): void {

    if (this.itemsView.length > 0 && !this.showEmptyValue && !this.view.value && this.view.value != '0') {
      this.view.formattedValue = this.itemsView[0].title;
      this.value = this.view.value = this.itemsView[0].id;
    }

    if (this.value != this.view.value) {
      this.value = this.view.value;
      this.formattedValue = this.view.formattedValue;
    }
    
  }


  /**
  * Returns true If any item view is readOnly,
  */
  public isReadOnly(newValue: any): boolean {
    return this.itemsView.filter(x => x.readOnly).length > 0;
  }

  public selectedChildItemView(): InputViewModel {
    for (let view of this.itemsView) {
      // Return not null value.
      if (view.id == this.view.value)
        return view;
    }

    if (!this.showEmptyValue && this.itemsView.length > 0)
      return this.itemsView[0];

    // This line only would be executed if no value is set.
    return null;
  }

  public isDisabled(newValue: any): boolean {
    let itemView = this.getItemViewById(newValue);
    return itemView && itemView.disabled;
  }

  private getItemViewById(id: string): InputViewModel {
    for (let view of this.itemsView) {
      if (view.id == id)
        return view;
    }
    return null;
  }

  beforeNotifyToListener(actionView: InputViewModel): void {    
    this.view.formattedValue = actionView.title;
    this.view.value = actionView.value;
  }

  readOnlyClick(actionView?: InputViewModel): void {
    let v: InputViewModel = actionView == null ? this.selectedChildItemView() : actionView;
    super.notifyIfReadOnly(v, null);

  }
}