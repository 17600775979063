<backdrop-container>
  <!-- Customization (modified: showFooter, okClick) -->
  <regular-popup *ngIf="visible"
                 [id]="id"
                 [title]="strings.PriceList"
                 width="1000px"
                 fullHeight="true"
                 [showHeader]="true"
                 [showFooter]="true"
                 position="right"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (okClick)="onOkClicked()"
                 (onClosed)="onPopupClosed()">
    <!-- End customization -->
    <div card-type="breadcrumb" *ngIf="activeReport != null && stack.size > 0">
      <nav role="navigation">
        <ol class="breadcrumb mb-0 pt-0 pb-2 ml-3">
          <li *ngFor="let conf of stack; let last = last" [class.has-error]="hasReportError(conf)" [class.active]="last" class="breadcrumb-item">
            <a (click)="onReportClicked(conf.longId)" role="button">{{conf.text}} <span class="sub-text">({{conf.longId}})</span></a>
          </li>
        </ol>
      </nav>
    </div>

    <div card-type="header" class="price-report pt-2">
      <div class="row px-4 mt-0">
        <div class="col-auto">
          <co-image key="product" width="32px" imageSet="primary-light" height="32px"> </co-image>
        </div>
        <div class="col report-title align-middle">
          {{reportTitle}}
        </div>
      </div>
      <div class="px-4"><p class="my-2">{{reportCategoryName}}</p></div>
    </div>
    <!--Body part-->
    <div card-type="body" class="price-report price-report-table px-4 pt-4" *ngIf="activeReport != null">
      <!-- Customization (modified: allReports, onSelectedValuesChanged) -->
      <price-list-report (childReportClicked)="onReportClicked($event)" [errorReportsConfIds]="" [allReports]="reports" [report]="activeReport" (onSelectedTextsChanged)="onSelectedTextsChanged($event)" (onSelectedValuesChanged)="onSelectedValuesChanged($event)"></price-list-report>
      <!-- End customization -->
    </div>
  </regular-popup>
</backdrop-container>